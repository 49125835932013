<template>
  <div class="userInfoAuditList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #searchSlot>
        <v-datepicker label="申请时间段" :startTime.sync="searchParams.regFromDate" :endTime.sync="searchParams.regToDate"></v-datepicker>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="性别" v-model="searchParams.sex" :options="sexOpts"></v-select>
        <v-select label="身份" v-model="searchParams.userType" :options="userTypeOpts"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.bindHouseAccredit !== '1'" text="编辑" type="text" permission="examine" @click="edit(scope.row)"></v-button>
        <el-dropdown v-if="scope.row.bindHouseAccredit !== '1'" trigger="click" @command="handleMore">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }">
              <span>审核通过</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 2 }">
              <span>拒绝</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <el-dialog
        title="拒绝原因"
        width="540px"
        :visible.sync="refuseDialogVisible">
        <el-form :model="refuseForm" ref="refuseForm">
          <el-form-item prop="refuseReason" :rules="[{ required: true, message: '拒绝原因不能为空！' }]">
            <v-textarea v-model.trim="refuseForm.refuseReason"></v-textarea>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <v-button @click="refuseDialogVisible=false" style="margin-right:10px" type="" text="取 消"></v-button>
          <v-button type="primary" @click="refuseConfirm" text="确 定"></v-button>
        </span>
      </el-dialog>
    </list>
  </div>
</template>

<script>
import { getListURL, auditURL, exportUnAuditListURL } from './api'
import { sexMap, sexOpts, userTypeOpts } from './map'
import { Dropdown, DropdownMenu, DropdownItem, Dialog } from 'element-ui'
import moment from 'moment'
import { communityParams, tenantParams } from 'common/select2Params'
import Vue from 'vue'
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
export default {
  name: 'UserInfoAuditList',
  data () {
    return {
      curRow: undefined,
      refuseDialogVisible: false,
      refuseForm: {
        refuseReason: ''
      },
      refuseReason: '',
      communityParams,
      tenantParams,
      searchUrl: getListURL,
      exportUrl: exportUnAuditListURL,
      sexOpts,
      userTypeOpts,
      searchParams: {
        inuser: '',
        sex: undefined,
        communityId: '',
        regionId: '',
        regFromDate: '',
        regToDate: '',
        userState: 0
      },
      headers: [
        {
          prop: 'inuser',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            return sexMap[row.role]
          }
        },
        {
          prop: 'address',
          label: '项目住址'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'applyTime',
          label: '申请时间'
        }
      ]
    }
  },
  mounted () {
    let format = 'YYYY-MM-DD'
    this.searchParams.regFromDate = moment().subtract(7, 'days').format(format)
    this.searchParams.regToDate = moment().format(format)
    const { fromPage } = this.$route.query
    if ( fromPage === 'workbench') {
      this.searchParams = {
        inuser: '',
        sex: undefined,
        communityId: '',
        regionId: '',
        regFromDate: '',
        regToDate: '',
        userState: 0
      }
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'userInfoAuditEdit',
        query: {
          id: row.id
        }
      })
    },
    showRefuseDialog (row) {
      this.refuseDialogVisible = true
      this.curRow = row
      this.refuseForm.refuseReason = ''
      this.$nextTick(() => {
        this.$refs.refuseForm.resetFields()
      })
    },
    // 审核通过
    async audit (row) {
      let isConfirm = await this.$confirm('确认审核？')
      if (isConfirm) {
        let data = await this.$axios.post(auditURL, {
          isExamine: 1,
          assetId: row.id,
          dataObject: `${row.inuser}-${row.mobileNum}`
        }, {
          transformRequest: [data => {
            return this.$qs.stringify(data)
          }]
        })
        if (data.status === 100) {
          // 提示操作成功
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
          // 刷新列表
          this.$refs.list.searchData()
        }
      }
    },
    // 审核拒绝
    async refuse () {
      let data = await this.$axios.post(auditURL, {
        isExamine: 2,
        assetId: this.curRow.id,
        rejectReason: this.refuseForm.refuseReason,
          dataObject: `${this.curRow.inuser}-${this.curRow.mobileNum}`
      }, {
        transformRequest: [data => {
          return this.$qs.stringify(data)
        }]
      })
      if (data.status === 100) {
        // 提示操作成功
        this.$message({
          type: 'success',
          message: '操作成功',
          center: true
        })
        this.refuseDialogVisible = false
        // 刷新列表
        this.$refs.list.searchData()
      }
    },
    refuseConfirm () {
      this.$refs.refuseForm.validate((valid) => {
        if (valid) {
          this.refuse()
        }
      })
    },
    handleMore (data) {
      if (data.type === 1) {
        this.audit(data.row)
      } else if (data.type === 2) {
        this.showRefuseDialog(data.row)
      }
    }
  }
}
</script>
